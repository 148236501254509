import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import logo from '.././assets/logo.png';
import userIcon from '.././assets/usersIcon.svg';

function Home() {
    return (
        <div className='home_m'>
             <div class="w100 headerContainer">
                <div class="p-0">
                    <div class="header">
                        <div class="logoSec">
                            <div class="logo">
                                <img src={(logo)} alt="Logo" />
                            </div>
                        </div>
                        <div class="munuContainer">
                            <div class="menuLists">
                                <div class="navigation-buttons">
                                    <a href="/home" class="menuBtn navigation-button active">Home</a>
                                    <a href="/transaction" class="menuBtn navigation-button">Transaction</a>
                                    <a href="#" class="menuBtn navigation-button">Weaver</a>
                                    <a href="/sareedesign" class="menuBtn navigation-button">Saree Design</a>
                                    <a href="#" class="menuBtn navigation-button">Settings</a>
                                </div>
                                <div class="logOut">
                                    <p class="userName">Admin</p>
                                    <div>
                                        <img src={(userIcon)} class="img-fluid" alt="User Icon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='introduction'>
                <h2 className='text-center'>Welcome to Shri Selvi Fabric</h2>
            </div>
            <div className="data-container">
                <div className='text-center saree-design-preview'>
                    <img src="https://singhanias.in/cdn/shop/collections/e925ca5d14eb8518956368f4233d57c1.jpg?v=1687192265" alt="Featured Saree Design" className="featured-image" />
                </div>
            </div>
        </div>
    );
}

export default Home;